import { useTranslation } from "react-i18next";

import classes from './Welcome.module.css';

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <h2>{t("welcome_page.title")}</h2>
        <div className={classes.innerContent}>
          <div>
            <h3>{t("welcome_page.para1_title")}</h3>
            {t("welcome_page.para1")}
          </div>
          <div>
            <b>{t("welcome_page.para2_title")}</b><br/><br/>
            <div>{t("welcome_page.para2")}</div>

            <div>{t("welcome_page.para3")}</div>
            <div>{t("welcome_page.para4")}</div>
          </div>
          <div>
            <br/><b>{t("welcome_page.para5_title")}</b>
            <div>
              {t("welcome_page.para5")}
            </div>
          </div>
          <div>
            <br/><b>{t("welcome_page.para6_title")}</b>
            { window.localStorage.getItem("i18nextLng")==="en" ?
              <div>
                {t("welcome_page.para6")}
              </div>
              : null
            }
          </div>
        </div>
    </div>
  );
};

export default Welcome;