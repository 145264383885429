import React, { useEffect, Suspense } from 'react';
import {Routes, Route} from 'react-router-dom';
import SideBar from "./components/SideBar/SideBar";
import Welcome from "./pages/Welcome";
import ErrorPage from "./components/ErrorPage";

import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './App.module.css';

const LoadingPage = React.lazy(() => import('./components/LoadingPage'));
const TeaTable = React.lazy(() => import('./pages/projects/TeaTable'));
const SmallCabinet = React.lazy(() => import('./pages/projects/SmallCabinet'));
const WoodAgingP1 = React.lazy(() => import('./pages/projects/WoodAgingP1'));
const WhiskeyCabinet = React.lazy(() => import('./pages/projects/WhiskeyCabinet'));
const WhiskeyWineStand = React.lazy(() => import('./pages/projects/WhiskeyWineStand'));
const SmallCabinet4Drawers = React.lazy(() => import('./pages/projects/SmallCabinet4Drawers'));
const BottlesAnchor = React.lazy(() => import('./pages/projects/BottlesAnchor'));

function App() {

  useEffect(() => {
    const isBrowserCompatible = checkBrowserCompatibility();
    if (!isBrowserCompatible) {
      alert('Your browser is not supported. Please use a modern browser.');
    }
  }, []);

  const checkBrowserCompatibility = () => {
    // Check for browser features and compatibility here
    // You can use feature detection methods or check for specific browser versions

    // Example: Check if the browser supports the 'fetch' API
    if (!window.fetch) {
      return false;
    }

    // Add more compatibility checks as needed

    // If all checks pass, return true
    return true;
  };

  return (
    <Suspense fallback={<LoadingPage />} >
      <div className={classes.main}>

        <div className="sideBar">
          <SideBar />
        </div>

        <div style={{marginBottom: 50+'px'}} >
          <Routes>
            <Route path="/" exact element={<Welcome />} />
            <Route path="/home" exact element={<Welcome />} />
            <Route path="/Tea-table" exact element={<TeaTable />} />
            <Route path="/Small-cabinet" exact element={<SmallCabinet />} />
            <Route path="/Wood-aging-part" exact element={<WoodAgingP1 />} />
            <Route path="/Whiskey-cabinet" exact element={<WhiskeyCabinet />} />

            <Route path="/Whiskey-wine-stand" exact element={<WhiskeyWineStand />} />
            <Route path="/Small-cabinet-4-drawers" exact element={<SmallCabinet4Drawers />} />
            <Route path="/Bottles-anchor" exact element={<BottlesAnchor />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </div>
    </Suspense>
  );
}

export default App;
