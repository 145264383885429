import classes from './Footer.module.css';

const Footer = () => {
  return (
    <div className={classes.footer+" "+classes.centralText + " mt-auto text-center"}>
      &copy; {new Date().getFullYear()} Copyright: Roy Keren
    </div>
  );
};

export default Footer;