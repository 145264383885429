import { useTranslation } from "react-i18next";

import classes from './ErrorPage.module.css';
import {Container} from "react-bootstrap";

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Container className={classes.container}>
      <h1>{t("error.title")}</h1>
      <p>{t("error.body")}</p>
    </Container>
  );
};

export default ErrorPage;