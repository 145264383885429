import React from 'react';
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import classes from './SideBar.module.css';
import LangSelector from "../LangSelector";
import Footer from "../footer/Footer";

const Sidebar = () => {
  const { t } = useTranslation();
  const currentURL = window.location.pathname;

  return (
    <Navbar expand="lg" className={`navbar navbar-dark bg-dark ${classes.fullHeight}`} bg="dark">
      <Container className={classes.columnItems}>
        <Navbar.Brand href="/">
          {t("menu.logo_text")}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={classes.columnItems}>
          <Nav className={`me-auto ${classes.columnItems}`} activeKey={currentURL} >
            <Nav.Link href="/Tea-table" className={currentURL.includes("Tea-table")? classes.activeTag : ""} >
              {t("menu.tea_table")}
            </Nav.Link>
            <Nav.Link href="/Small-cabinet" className={currentURL.includes("Small-cabinet")? classes.activeTag : ""} >
              {t("menu.small_cabinet")}
            </Nav.Link>
            <Nav.Link href="/Wood-aging-part" className={currentURL.includes("Wood-aging-part")? classes.activeTag : ""} >
              {t("menu.wood_aging_part")}
            </Nav.Link>

            {/*<NavDropdown title="Equipment" id="basic-nav-dropdown">*/}
            {/*  <NavDropdown.Item href="/Minimum-Equipment" className={currentURL.includes("Minimum-Equipment")? classes.activeTag : ""} >*/}
            {/*    {t("menu.min_equip")}*/}
            {/*  </NavDropdown.Item>*/}
            {/*  <NavDropdown.Item href="/Extra-Equipment" className={currentURL.includes("Extra-Equipment")? classes.activeTag : ""} >*/}
            {/*    {t("menu.extra_equip")}*/}
            {/*  </NavDropdown.Item>*/}
            {/*</NavDropdown>*/}

            <Nav.Link href="/Whiskey-cabinet" className={currentURL.includes("Whiskey-cabinet")? classes.activeTag : ""} >
              {t("menu.whiskey_cabinet")}
            </Nav.Link>
            <Nav.Link href="/Whiskey-wine-stand" className={currentURL.includes("Whiskey-wine-stand")? classes.activeTag : ""} >
              {t("menu.whiskey_wine_stand")}
            </Nav.Link>
            <Nav.Link href="/Small-cabinet-4-drawers" className={currentURL.includes("Small-cabinet-4-drawers")? classes.activeTag : ""} >
              {t("menu.small_cabinet_4_drawers")}
            </Nav.Link>
            <Nav.Link href="/Bottles-anchor" className={currentURL.includes("Bottles-anchor")? classes.activeTag : ""} >
              {t("menu.bottles_anchor")}
            </Nav.Link>

            <LangSelector />
          </Nav>

          <Footer />
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
};

export default Sidebar;