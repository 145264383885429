import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import './i18n'; // import i18n (needs to be bundled ;))

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback="<span>Loading...</span>">
      <App />
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);
